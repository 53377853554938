/** @jsx jsx */
import React from "react"
import SEO from "../SEO"
import Layout from "../Layout"
import { Flex, Box, Heading, Text, jsx } from "theme-ui"
import AnimatedCheckmark from "../AnimatedCheckmark"
import { Link } from "gatsby"

interface ThankYouPageProps {
  header?: string
  subHeader?: string
}

const ThankYouPage = (props: ThankYouPageProps): JSX.Element => {
  const header = props.header ?? "Thank you for your submission"
  const subHeader = props.subHeader ?? "We will get in touch with you shortly."

  return (
    <Box>
      <SEO title="Thank You" />
      <Layout>
        <Flex
          sx={{
            minHeight: "50vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <AnimatedCheckmark />
            <Box>
              <Heading sx={{ fontSize: 5 }}>{header}</Heading>
              <Text sx={{ fontSize: 3 }} mb={2} variant="secondary">
                {subHeader}
              </Text>
              <Link
                sx={{
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                  color: "#068ff4",
                  fontsize: 3,
                }}
                to="/"
              >
                Go To Home{" "}
              </Link>
            </Box>
          </Box>
        </Flex>
      </Layout>
    </Box>
  )
}

export default ThankYouPage
